import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bg-image pt-5" }
const _hoisted_2 = { class: "login-header text-center" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LivePayRegisterForm = _resolveComponent("LivePayRegisterForm")!
  const _component_LoadAccountForm = _resolveComponent("LoadAccountForm")!
  const _component_AccountLoaded = _resolveComponent("AccountLoaded")!
  const _component_b_container = _resolveComponent("b-container")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_b_container, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  src: require('@/assets/img/logo/drivebuzz_Logo_quer.svg'),
                  alt: "Logo",
                  class: "my-3",
                  style: {"width":"250px"}
                }, null, 8, _hoisted_3)
              ]),
              (_ctx.thirdPartyPayment && _ctx.showView === 'live-pay-register-form')
                ? (_openBlock(), _createBlock(_component_LivePayRegisterForm, {
                    key: 0,
                    "student-education-id": _ctx.thirdPartyPayment.studentEducationId,
                    "initial-email": _ctx.thirdPartyPayment.emailAddress
                  }, null, 8, ["student-education-id", "initial-email"]))
                : _createCommentVNode("", true),
              (_ctx.showView === 'load-account-form')
                ? (_openBlock(), _createBlock(_component_LoadAccountForm, {
                    key: 1,
                    "student-education-id": _ctx.thirdPartyPayment.studentEducationId,
                    "initial-amount-in-eur": _ctx.thirdPartyPayment.amountInEur,
                    "mandate-id": _ctx.livePayMandateId
                  }, null, 8, ["student-education-id", "initial-amount-in-eur", "mandate-id"]))
                : _createCommentVNode("", true),
              (_ctx.showView === 'account-loaded')
                ? (_openBlock(), _createBlock(_component_AccountLoaded, {
                    key: 2,
                    "amount-in-eur": _ctx.thirdPartyPayment.amountInEur
                  }, null, 8, ["amount-in-eur"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}