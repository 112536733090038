
import { Options, Prop, Vue } from "vue-property-decorator";
import { namespace } from "s-vuex-class";
import isArray from "lodash/isArray";
import { bic, iban } from "@/validators/validators";
import useVuelidate from "@vuelidate/core";
import { email, minLength, required } from "@vuelidate/validators";
import FscMultiselect from "@/components/FscMultiselect.vue";

const ThirdPartyPaymentModule = namespace("third-party-payment");
const CountryModule = namespace("country");
@Options({
  components: { multiselect: FscMultiselect },
  validations: {
    country: { required },
    firstName: { required, minLength: minLength(2) },
    lastName: { required, minLength: minLength(2) },
    iban: { required, validIban: (value: string) => iban(value) },
    bic: { required, validBic: (value: string) => bic(value) },
    postalCode: { required, minLength: minLength(4) },
    location: { required, minLength: minLength(3) },
    streetAndNumber: { required, minLength: minLength(10) },
    email: { required, email },
  },
})
export default class LivePayRegisterForm extends Vue {
  public v$ = useVuelidate();

  @ThirdPartyPaymentModule.Action("livePayMandateActivate")
  public livePayMandateActivateAction: any;

  @ThirdPartyPaymentModule.Getter("getIsLoading")
  public livePayMandateLoading: any;

  @CountryModule.Action("findAll")
  public findCountriesAction: any;

  @CountryModule.Getter("getDataList")
  public countries: any;

  @CountryModule.Getter("getIsLoading")
  public countriesLoading: any;

  @Prop()
  public studentEducationId!: any;
  @Prop()
  private initialEmail!: any;

  private country = null;
  private firstName = null;
  private lastName = null;
  private iban = null;
  private bic = null;
  private postalCode = null;
  private location = null;
  private streetAndNumber = null;
  private editedEmail: string | null = null;

  get email(): string | null {
    if (this.editedEmail !== null) {
      return this.editedEmail;
    }
    if (this.initialEmail) {
      return this.initialEmail;
    }
    return null;
  }

  set email(val: string | null) {
    this.editedEmail = val;
  }

  public mounted(): void {
    if (!isArray(this.countries) || this.countries.length === 0) {
      this.loadCountries();
    }
  }

  get loading(): boolean {
    return this.livePayMandateLoading || this.countriesLoading;
  }

  private loadCountries(): void {
    this.findCountriesAction({
      resource: "countries",
      hideSuccessMessage: true,
    });
  }

  private activateLivePayMandate(): void {
    const data = {
      studentEducationId: this.studentEducationId,
      country: this.country,
      firstName: this.firstName,
      lastName: this.lastName,
      iban: this.iban,
      bic: this.bic,
      postalCode: this.postalCode,
      location: this.location,
      streetAndNumber: this.streetAndNumber,
      email: this.email,
    };
    this.livePayMandateActivateAction({
      token: this.$route.query.token,
      data: data,
    });
  }

  private onSubmit(): void {
    //@ts-ignore
    this.v$.$touch();

    //@ts-ignore
    if (this.v$.$invalid) {
      return;
    }
    this.activateLivePayMandate();
  }
}
