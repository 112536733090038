
import { Options, Prop, Vue } from "vue-property-decorator";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import LoadAccountBtn from "@/components/Pages/Home/Account/LoadAccount/LoadAccountBtn.vue";
import Validation from "@/components/Validation.vue";
import FaceConfused from "@/components/Pages/Home/Account/LoadAccount/Emojies/FaceConfused.vue";
import FaceSmileWink from "@/components/Pages/Home/Account/LoadAccount/Emojies/FaceSmileWink.vue";
import { namespace } from "s-vuex-class";
import useVuelidate from "@vuelidate/core";
import { minValue, required } from "@vuelidate/validators";

const ThirdPartyPaymentModule = namespace("third-party-payment");
@Options({
  components: {
    FaceSmileWink,
    FaceConfused,
    LoadAccountBtn,
    EducationTabs,
    Validation,
  },
  validations: {
    amountInEur: { required, minValue: minValue(1) },
  },
})
export default class LoadAccountForm extends Vue {
  public v$ = useVuelidate();

  @ThirdPartyPaymentModule.Action("create")
  public livePayAction: any;

  @ThirdPartyPaymentModule.Action("setAmountInEur")
  public setAmountInEurAction: any;

  @ThirdPartyPaymentModule.Getter("getIsLoading")
  public livePayLoading: any;

  @Prop()
  public studentEducationId!: any;
  @Prop()
  private initialAmountInEur!: any;
  @Prop()
  private mandateId!: any;

  private editedAmountInEur: number | null = null;

  get amountInEur(): number | null {
    if (this.editedAmountInEur !== null) {
      return this.editedAmountInEur;
    }
    if (this.initialAmountInEur) {
      return this.initialAmountInEur;
    }
    return null;
  }

  set amountInEur(val: number | null) {
    this.editedAmountInEur = val;
  }

  protected validate(): boolean {
    //@ts-ignore
    this.v$.$touch();
    //@ts-ignore
    return !this.v$.$invalid;
  }

  private livePay(): void {
    this.setAmountInEurAction(this.amountInEur);
    const data = {
      studentEducationId: this.studentEducationId,
      amountInEur: this.amountInEur,
      mandateId: this.mandateId,
    };
    this.livePayAction({
      resource: `/live-pay/payment/from-third-party/${this.$route.query.token}`,
      hideSuccessMessage: true,
      data: data,
    });
  }

  private onLoadAccountClick(): void {
    if (!this.validate()) {
      return;
    }
    this.livePay();
  }

  private onCollectMoneyClick(): void {
    this.$emit("onCollectMoney");
  }
}
