import bicValidator from "bic-validator";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const IBAN = require("iban");

export function bic(value: string): boolean {
  return bicValidator.isValid(value);
}

export function iban(value: string): boolean {
  return IBAN.isValid(value);
}
